(function($) {
    $(document).ready(function () {
        const toTop = $('#to-top');
        const sideNav = $('#side-nav');
        const sideNavMarker = $('#side-nav-marker');
        const header = $('#header');
        const onepagerContents = $('.js-onepager-content');
        const blockRight = $('.block-right');
        const rightToFix = $('#right-to-fix');

        toTop.click(function () {
            $('html, body').animate({ scrollTop: 0 }, 300);
        });

        // ---------------------------------------------------
        // ready
        // ---------------------------------------------------
        scrollFunction();

        // ---------------------------------------------------
        // scroll
        // ---------------------------------------------------
        function scrollFunction() {
            var windowScrollTop = $(window).scrollTop();

            if (windowScrollTop > 200) {
                header.addClass('scrolled');
            } else {
                header.removeClass('scrolled');
            }

            //
            // side nav
            //
            if (sideNavMarker.length > 0) {
                if (windowScrollTop > (sideNavMarker.offset().top - 130)) {
                    sideNav.addClass('fixed');
                } else {
                    sideNav.removeClass('fixed');
                }
            }

            //
            // block right on career pages
            //
            if ($('.page-jobs').length > 0 || $('.mod-reasons').length > 0 || $('.page-onboarding').length > 0) {
                if ( (windowScrollTop > (blockRight.offset().top - 130))) {
                    rightToFix.removeClass('abs');
                    rightToFix.addClass('fixed');
                    rightToFix.css({
                        'top': 0
                    });

                    if (windowScrollTop > ($('.mod-contact-info').offset().top - blockRight.height() - 625)) {
                        rightToFix.removeClass('fixed');
                        rightToFix.addClass('abs');
                        rightToFix.css({
                            'top': $('.mod-contact-info').offset().top - blockRight.offset().top - rightToFix.height() - 20
                        });
                    }
                } else if ($('.mod-contact-info').offset().top - $('.blockRight').height()) {

                } else {
                    rightToFix.removeClass('fixed');
                }
            }

            if (sideNav.hasClass('onepager')) {
                var somethindIsIntoView = false;
                onepagerContents.each(function() {
                    var that = this;
                    if (isIntoView(that)) {
                        somethindIsIntoView = true;
                        sideNav.find('a').removeClass('current');
                        sideNav.find('[data-onepager-link="' + $(that).data('onepager-title') + '"]').addClass('current');
                    }

                    if (!somethindIsIntoView) {
                        sideNav.find('a').removeClass('current');
                    }
                });
            }
        }

        $(window).scroll(function () {
            scrollFunction();
        });

        // ---------------------------------------------------
        // hamburger
        // ---------------------------------------------------
        const hamburger = $('#hamburger');
        hamburger.click(function (e) {
            e.preventDefault();
            hamburger.toggleClass('no-hamburger');
            $('#nav-overlay').toggleClass('in');
            header.toggleClass('nav-overlay-in');
        });


        // ---------------------------------------------------
        // execute at document ready
        // ---------------------------------------------------
        $('#nav-overlay').find('.nav-block-title.w-icon').click(function () {
            $(this).parent().toggleClass('open');
        });


        // ---------------------------------------------------
        // count up home numbers
        // ---------------------------------------------------
        function isIntoView(elem) {
            var documentViewTop = $(window).scrollTop();
            var documentViewBottom = documentViewTop + $(window).height();

            var elementTop = $(elem).offset().top;
            var elementBottom = elementTop + $(elem).height();

            return ((elementTop + 400) >= documentViewTop) && ((elementTop + 200) <= documentViewBottom);
            //return ((elementBottom <= documentViewBottom) && ((elementTop + 100) >= documentViewTop));
        }


        // ---------------------------------------------------
        // onepager navi
        // ---------------------------------------------------
        $('.js-onepager-nav-item').click(function (e) {
            e.preventDefault();
            var scrollTarget = 0;
            var thisOnepagerLink = $(this).attr('data-onepager-link');

            if ( thisOnepagerLink === 'top' ) {
                scrollTarget = 0;
            } else {
                scrollTarget = $('#' + thisOnepagerLink).offset().top;
            }

            $('html, body').animate({ scrollTop: scrollTarget }, 300);
        });


        // ---------------------------------------------------
        // borlabs cookie
        // ---------------------------------------------------
        $('.js-cookie-box').click(function(e) {
            e.preventDefault();
            window.BorlabsCookie.showCookieBox();
        });

    });
}(jQuery));
